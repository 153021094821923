<template>
  <div>
    <van-form ref="form" error-message-align="right" input-align="right">
      <van-cell-group inset :border="false">
        <!-- 申请 -->
        <van-field readonly label="申请日期" :value="timeFormat(_report.reportTime)"/>
        <!-- 申请内容 申请提交后 是只读 -->
        <van-field
            v-if="_report.applicationType && _report.applicationType.toString() === '1'"
            required
            readonly
            label="转二阶段日期"
            @click="(auth&& !_report.done) ? showStage2TimePicker() : isShowStage2TimePicker=false"
            placeholder="请选择"
            :right-icon="auth && !_report.done ? 'arrow' : ''"
            :value="timeFormat(_report.scheduledDate)"/>
        <van-field
            v-model="_report.detail"
            class="my-textarea"
            label="申请理由"
            type="textarea"
            maxlength="300"
            show-word-limit
            required
            :readonly="_isReadOnly"
            :rules="[{ required: true, message: '请填写内容' }]"
            :autosize="{ maxHeight: 400, minHeight: 150 }"
        />

        <!-- 审核 已提交过申请（有id） && 有权限 && 流程未结束 显示 -->
        <van-field
            v-if="_report.applicationId && auth && !_report.done"
            v-model="myRemarks"
            class="my-textarea"
            label="建议与意见"
            placeholder="如果拒绝，请填写拒绝原因"
            type="textarea"
            maxlength="300"
            show-word-limit
            :rules="[{ validator, message: '请填写拒绝原因' }]"
            :autosize="{ maxHeight: 400, minHeight: 150 }"
        />
      </van-cell-group>
      <div class="btn-group">
        <template v-cloak v-if="_report.applicationId && auth && !_report.done">
          <van-button
              color="var(--secondMainColor)"
              native-type="button"
              :loading="submitting && _report.approvalResult === Operation.REJECT"
              @click="onSubmit(2)"
          >
            拒绝
          </van-button>
          <van-button
              color="var(--mainColor)"
              native-type="button"
              :loading="submitting && _report.approvalResult === Operation.AGREE"
              @click="onSubmit(1)"
          >
            通过
          </van-button>
        </template>

        <template v-cloak v-else>
          <van-button v-if="!_report.authToChangeDate" color="var(--secondMainColor)" native-type="button"
                      @click="close">
            返回
          </van-button>
          <!--          终止转二阶段-->
          <van-button
              v-if="_report.authToChangeDate && _report.applicationId"
              color="#e14440"
              native-type="button"
              :loading="submitting"
              @click="onCancelStage2()"
          >
            终止转二阶段
          </van-button>
          <van-button
              v-if="!_report.applicationId"
              color="var(--mainColor)"
              native-type="button"
              :loading="submitting"
              @click="onSubmit()"
          >
            提交
          </van-button>
          <van-button
              v-if="_report.needToReapply"
              color="var(--mainColor)"
              native-type="button"
              :loading="submitting"
              @click="$emit('reset')"
          >
            重新申请
          </van-button>
          <template v-if="_report.authToChangeDate">
            <van-button
                color="var(--secondMainColor)"
                native-type="button"
                :loading="submitting"
                @click="showStage2TimePicker()"
            >
              修改日期
            </van-button>
          </template>
        </template>
      </div>
    </van-form>
    <van-popup :value="isShowStage2TimePicker" round position="bottom"
               close-on-click-overlay
               @click-overlay="onCancelStage2TimePicker()"
    >
      <van-datetime-picker
          v-model="tempScheduledDate"
          type="date" title="选择年月日"
                           @cancel="onCancelStage2TimePicker()"
                           @confirm="onConfirmStage2TimePicker"
                           :min-date="minStage2Date"
                           :max-date="maxStage2Date"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {ExecutionStatus, Operation} from "@/api/consts";
import {handleTransfer} from "@/api/hoss";
import {Dialog} from "vant";

export default {
  props: ["report", "auth", "confirm", "close"],
  data() {
    return {
      tempScheduledDate: null,
      showCalendar: false,
      minDate: dayjs().subtract(1, "month").toDate(),
      maxDate: new Date(),
      submitting: false,
      myRemarks: "",
      isShowStage2TimePicker: false,
      // 最小日期明天
      minStage2Date: dayjs().add(1, "day").toDate(),
      // 最大日期一年后
      maxStage2Date: dayjs().add(7, "day").toDate(),
    };
  },
  computed: {
    Operation() {
      return Operation
    },
    _report: {
      get() {
        return this.report;
      },
      set(val) {
        this.$emit("update:report", val);
      },
    },
    _isReadOnly() {
      if (this._report) {
        if (this._report.applicationId) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    onConfirmStage2TimePicker(val) {
      this._report.scheduledDate = dayjs(val).valueOf();
      if (this._report.authToChangeDate) {
        if (!(this._report.applicationId && this.auth && !this._report.done)) {
          handleTransfer(this._report.applicationId, this._report.scheduledDate / 1000, ExecutionStatus.DATE_CHANGED).then(res => {
            this.$toast("修改成功");
            this.isShowStage2TimePicker = false;
            this.$emit("reInit", "");
          }).catch(err => {
            this.$toast("修改失败");
            this.isShowStage2TimePicker = false;
            this._report.scheduledDate = this.currentScheduleDate;
          });
        } else {
          this.isShowStage2TimePicker = false;
          this._report.scheduledDate = dayjs(val).valueOf();
        }
      } else {
        this.isShowStage2TimePicker = false;
        this._report.scheduledDate = dayjs(val).valueOf();
      }
    },
    onCancelStage2() {
      Dialog.confirm({
        title: "终止转二阶段确认",
        message: "确认终止转二阶段申请",
        className: "to-transfer-2-dialog",
        cancelButtonText: "返回",
        confirmButtonText: "确认终止",
      })
          .then(async () => {
            await handleTransfer(this._report.applicationId, null, ExecutionStatus.CANCELED);
            this.$toast("终止成功");
            this.$emit("reInit", "");
          })
          .catch(() => {
            /* on cancel */
          });
    },
    onCancelStage2TimePicker() {
      this.isShowStage2TimePicker = false;
      if (!this._report.scheduledDate) {
        this._report.scheduledDate = this.currentScheduleDate;
      }
    },
    showStage2TimePicker() {
      this.currentScheduleDate = this._report.scheduledDate;
      this.isShowStage2TimePicker = true;
    },
    validator() {
      const {approvalResult} = this._report;
      const remarks = this.myRemarks;
      return !!(approvalResult === Operation.AGREE || (approvalResult === Operation.REJECT && remarks) || (approvalResult === Operation.SUBMIT && remarks));
    },
    timeFormat(t) {
      if (!t) return "";
      return dayjs(t * 1).format("YYYY-MM-DD");
    },
    async onSubmit(result = "") {
      this._report.approvalResult = result;
      await this.$refs.form?.validate();
      // 判断当前scheduleDate是否是Date类型
      if (this._report.scheduledDate) {
        if (this._report.scheduledDate instanceof Date) {
          this._report.scheduledDate = dayjs(this._report.scheduledDate).valueOf();
        }
      }
      try {
        this.submitting = true;
        this._report.remarks = this.myRemarks;
        await this.confirm(this._report);
        // this.$toast("保存成功！");
      } catch (error) {
        this.$toast("保存失败！请联系管理员或者稍后重试");
        console.error(error);
      }
      this.submitting = false;
    },
  }
  ,
}
;
</script>

<style lang="less" scoped>
.van-form {
  .van-cell-group {
    margin: 10px;
    border-radius: 10px;
  }

  .btn-group {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 2;

    .van-button {
      flex: 1;
      border-radius: 0;
    }
  }
}

//[v-cloak] {
//  display: none !important;
//}
// 解决屏幕闪烁问题
/deep/ [v-cloak] {
  display: none !important;
}

/deep/ .van-field__right-icon {
  padding: 0;
}

/deep/ .van-icon-arrow {
  color: var(--titleColor);
}

.van-popup-transfer2stage-confirm-dialog {
  // 宽度
  width: 88%;

  .dialog_title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
  }

  .dialog_content {
    padding: 0 20px 10px;
    font-size: 14px;
    color: var(--textColor);
    line-height: 0.8;
    // 居中
    text-align: center;


    .dialog_content_text {
      margin-bottom: 10px;

      .dialog_content_text_delay {
        color: #205af9;
      }

      .dialog_content_text_cancel {
        color: #e14440;
      }
    }
  }

  .dialog_bottom_actions {
    display: flex;
    justify-content: space-between;
    // 顶部一条线
    border-top: 1px solid var(--separatorColor);
    // 按钮各一半、文字居中


    .dialog_bottom_actions_cancel {
      // 文字居中
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48%;
      color: var(--titleColor);
    }

    .dialog_bottom_actions_confirm {
      background-color: var(--mainColor);
      width: 48%;
      height: 40px;
      font-size: 14px;
      color: white;
      // 文字居中
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

</style>
